/********************************

Post It
*********************************/

.postit-container {
  margin-top: 50px;
  position: relative;
  width: 260px;
  display: inline-flex;
  margin-right: 12px;
  margin-left: 12px;
  text-align: left;
}

.postit-container.suppl {
  z-index: 29;
  margin-top: 64px;
  margin-left: -260px;
  position: absolute;
}

.note {
  color: #333;
  position: relative;
  width: 300px;
  margin: 0 auto;
  padding: 20px;
  font-family: Satisfy;
  font-size: 30px;
  box-shadow: 0 10px 10px 2px rgba(0, 0, 0, 0.3);
}

.note .author {
  display: block;
  margin: 40px 0 0 0;
  text-align: right;
}

.postit-container i {
  display: inline-flex;
  font-size: 25px;
  margin-right: 10px;
}

.postit-container .title {
  font-size: 20px;
  display: inline-flex;
}

.postit-container .middle {
  font-size: 18px;
  display: block;
}

.postit-container .small {
  font-size: 16px;
  display: inline-flex;
  width: 48%;
}

.postit-container .small.left {
  text-align: left;
}

.postit-container .small.right {
  text-align: right;
}

.yellow {
  background: #eae672;
}

.yellow.left {
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  transform: rotate(2deg);
}

.yellow.right {
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

.postit-container.normal .pin {
  background-color: #aaa;
  display: block;
  height: 32px;
  width: 2px;
  position: absolute;
  left: 50%;
  top: -16px;
  z-index: 1;
}

.postit-container.normal .pin:after {
  background-color: #a31;
  background-image: radial-gradient(
    25% 25%,
    circle,
    hsla(0, 0%, 100%, 0.3),
    hsla(0, 0%, 0%, 0.3)
  );
  border-radius: 50%;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.1),
    inset 3px 3px 3px hsla(0, 0%, 100%, 0.2),
    inset -3px -3px 3px hsla(0, 0%, 0%, 0.2),
    23px 20px 3px hsla(0, 0%, 0%, 0.15);
  content: '';
  height: 12px;
  left: -5px;
  position: absolute;
  top: -10px;
  width: 12px;
}

.postit-container.normal .pin:before {
  background-color: hsla(0, 0%, 0%, 0.1);
  box-shadow: 0 0 0.25em hsla(0, 0%, 0%, 0.1);
  content: '';
  height: 24px;
  width: 2px;
  left: 0;
  position: absolute;
  top: 8px;
  transform: rotate(57.5deg);
  -moz-transform: rotate(57.5deg);
  -webkit-transform: rotate(57.5deg);
  -o-transform: rotate(57.5deg);
  -ms-transform: rotate(57.5deg);
  transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
}

.info-box-wrap {
  background: #fff;
  overflow: hidden;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.08);
}
.info-box-text-wrap {
  height: 40px !important;
  width: 120px;
  float: left;
  overflow: hidden;
}
.action-btns {
  float: left;
  width: 70px;
  overflow: hidden;
  position: relative;
  top: 12px;
  left: 6px;
}
.action-btns i {
  font-size: 18px;
  color: #78a737;
  margin-left: 3px;
}
.action-btns i:hover {
  transition: color 0.5s ease;
  color: #ccc;
  cursor: pointer;
}
.action-btns i.fa-heart-o {
  font-weight: bold;
}
.info-box-text-wrap h6.address {
  padding: 6px 5px 1px 0;
  margin: 0 0 0 0;
  font-family: 'Gantari Slab';
  color: #0c99c8;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info-box-text-wrap p {
  padding: 0 0 0 0;
  margin: 0 0 0 0;
}
.info-box-text-wrap p.price {
  color: #b25b00;
}

.info-box-wrap img {
  width: 40px !important;
  height: 40px;
  float: left;
  margin-right: 10px;
  padding-top: 0;
  margin-top: 0;
}
/*

.infoBox:before{
  content : " ";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position:absolute;
  top:-10px;
  left:130px;
}
*/

button.disabled,
button:disabled {
  cursor: not-allowed;
  border: none !important;

  opacity: 0.5 !important;
  transition: 0.2s ease-in;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.5 !important;
}

.btn-primary,
.btn-primary.disabled {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: 1px solid var(--button-background-color);

  font-weight: 600;

  transition: 0.2s ease-in;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
  background-color: var(--button-background-color);
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary.focus:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary.focus,
.btn-primary:focus {
  background-color: var(--button-background-color);
  border: 1px solid var(--button-background-color);
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: 1px solid var(--button-background-color);
}

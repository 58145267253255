/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

html,
body {
  height: 100%;

  position: relative;

  --sidebarwidth: 82px;
  --sidebarfullwidth: 400px;

  --orange-color: #df8d67;
  --orange-light-color: #ecd2c4;
  --dark-color: #191d32;
  --blue-color: #0e356f;
  --lavander-color: #d8d4f2;
  --grey-color: #ddeaf1;
}

.p-overlaypanel .p-overlaypanel-close,
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background-color: var(--orange-color) !important;
  background: var(--orange-color) !important;
  color: var(--white-color) !important;
  border: 1px solid var(--orange-color) !important;

  .p-overlaypanel-close-icon {
    color: inherit !important;
  }
}

mv-login,
mv-forgot-password,
mv-reset-password,
mv-first-connection,
mv-invitation {
  background-image: url(/assets/images/background/login-register.jpg);
  background-size: cover;
  background-position: center;
}

mv-login.heroes,
mv-forgot-password.heroes,
mv-reset-password.heroes,
mv-first-connection.heroes,
mv-invitation.heroes,
mv-login-as {
  background-image: url(/assets/images/background/patchwork.jpg);
  background-size: cover;
  background-position: center;
}

mv-login.geremy,
mv-forgot-password.geremy,
mv-reset-password.geremy,
mv-first-connection.geremy,
mv-invitation.geremy {
  background-image: url(/assets/images/background/login-geremy-transparent.jpg);
  background-size: cover;
  background-position: center;
}

body {
  overflow: visible !important;
  background-color: transparent !important;
}

body.overflowXHidden {
  overflow-x: hidden !important;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #8e8e8e80 #8e8e8e40;
}

*::-webkit-scrollbar-track {
  border-radius: 20px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

*::-webkit-scrollbar-thumb {
  border-radius: 20px;
}

a {
  color: var(--link-text-color);

  p,
  span {
    color: var(--link-text-color);
  }

  &:hover {
    color: var(--link-text-hover-color);

    p,
    span {
      color: var(--link-text-hover-color);
    }
  }
}

mv-uploader label {
  background-color: var(--white-color);
  color: var(--text-color);
}

@media (max-width: 768px) {
  .p-button {
    font-size: 0.8rem;
    margin: 0.5rem;
  }

  .p-dialog {
    width: 100vw !important;
    height: 100vh !important;
  }
}

button:not(
    .btn-action,
    .ck,
    .btn-secondary,
    .btn-info,
    .btn-success,
    .btn-warning,
    .btn-danger,
    .p-element,
    .p-button.p-button-outlined,
    .p-button.p-button-text,
    .tox-mbtn,
    .tox-tbtn,
    .plyr__controls__item,
    .plyr__control,
    .ngb-dp-arrow-btn,
    .ngb-tp-minute > .btn-link,
    .ngb-tp-hour > .btn-link
  ),
.p-button:not(
    p-button,
    .ck,
    .p-button-outlined,
    .p-button-text,
    .btn-action,
    .btn-secondary,
    .btn-info,
    .btn-success,
    .btn-warning,
    .btn-danger,
    .tox-mbtn,
    .tox-tbtn,
    .plyr__controls__item,
    .plyr__control,
    .ngb-dp-arrow-btn,
    .ngb-tp-minute > .btn-link,
    .ngb-tp-hour > .btn-link
  ) {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: 1px solid var(--button-background-color);

  font-weight: 600;

  p,
  span,
  a,
  i {
    color: var(--button-text-color);
  }

  &:hover {
    background-color: var(--button-background-hover-color);
    color: var(--button-text-hover-color);
    border-color: var(--button-background-hover-color);

    p,
    span,
    a,
    i {
      color: var(--button-text-hover-color);
    }
  }
}

.p-dialog {
  .p-dialog-header {
    padding: 1rem;

    background-color: var(--primary-color);

    .p-dialog-title {
      color: white;
    }
  }

  .p-dialog-content {
    padding-top: 0.5rem;
  }

  .p-dialog-header-icon.p-dialog-header-close.p-link.p-ripple {
    background-color: var(--orange-color);
    color: var(--white-color);
    border: 1px solid var(--orange-color);

    font-weight: 600;

    p,
    span,
    a,
    i {
      color: var(--white-color);
    }

    &:hover {
      background-color: var(--button-background-hover-color);
      color: var(--button-text-hover-color);
      border-color: var(--button-background-hover-color);

      p,
      span,
      a,
      i {
        color: var(--button-text-hover-color);
      }
    }
  }
}

.p-button {
  padding: 0.5rem 1rem;

  border-width: 2px;
}

.p-button.p-button-outlined,
.p-button.p-button-outlined:enabled,
.p-element.p-button-outlined .p-button,
.p-element.p-button-outlined:enabled .p-button {
  background-color: var(--white-color);
  color: var(--primary-color);
  border: 1.5px solid var(--primary-color);

  > p,
  > span,
  > a,
  > i {
    color: inherit;
  }

  &:hover {
    background-color: var(--primary-color) !important;
    color: var(--white-color) !important;
    border-color: var(--primary-color) !important;
  }

  &.btn-success {
    background-color: var(--white-color);
    color: var(--success-color);
    border-color: var(--success-color);

    &:hover {
      background-color: var(--success-hover-color) !important;
      color: var(--white-color) !important;
      border-color: var(--success-hover-color) !important;
    }
  }

  &.btn-info {
    background-color: var(--white-color);
    color: var(--info-color);
    border-color: var(--info-color);

    &:hover {
      background-color: var(--info-hover-color) !important;
      color: var(--white-color) !important;
      border-color: var(--info-hover-color) !important;
    }
  }

  &.btn-danger {
    background-color: var(--white-color);
    color: var(--error-color);
    border-color: var(--error-color);

    &:hover {
      background-color: var(--error-hover-color) !important;
      color: var(--white-color) !important;
      border-color: var(--error-hover-color) !important;
    }
  }

  &.btn-warning {
    background-color: var(--white-color);
    color: var(--warning-color);
    border-color: var(--warning-color);

    &:hover {
      background-color: var(--warning-hover-color) !important;
      color: var(--white-color) !important;
      border-color: var(--warning-hover-color) !important;
    }
  }

  &.btn-action {
    background-color: var(--white-color);
    color: var(--action-color);
    border-color: var(--action-color);

    &:hover {
      background-color: var(--action-color) !important;
      color: var(--white-color) !important;
      border-color: var(--action-color) !important;
    }
  }
}

.p-element.p-button-outlined.btn-action .p-button {
  background-color: var(--white-color);
  color: var(--action-color);
  border-color: var(--action-color);

  > p,
  > span,
  > a,
  > i {
    color: inherit;
  }

  &:hover {
    background-color: var(--action-color) !important;
    color: var(--white-color) !important;
    border-color: var(--action-color) !important;
  }
}

.btn:hover {
  background-color: var(--button-background-hover-color);
  color: var(--button-text-hover-color);
  border-color: var(--button-background-hover-color);
}

.badge-action,
.p-badge.badge-action {
  background-color: var(--action-button-background-color);
  color: var(--action-button-text-color) !important;
  border-color: var(--action-button-background-color);
}

.badge-orange,
.p-badge.badge-orange {
  background-color: var(--orange-color);
  color: var(--white-color) !important;
  border-color: var(--orange-color);
}

.badge-dark,
.p-badge.badge-dark {
  background-color: var(--dark-color);
  color: var(--white-color) !important;
  border-color: var(--dark-color);
}

.badge-lavander,
.p-badge.badge-lavander {
  background-color: var(--lavander-color);
  color: var(--white-color) !important;
  border-color: var(--lavander-color);
}

.text-action {
  color: var(--action-button-text-color);
}

.text-orange {
  color: var(--orange-color);
}

.background-action {
  background-color: var(--action-button-background-hover-color);
}

.border-action {
  border-color: var(--action-button-background-color);
}

.btn-orange,
.btn-orange.disabled {
  background-color: var(--orange-color) !important;
  background: var(--orange-color) !important;
  color: var(--white-color) !important;
  border: 1px solid var(--orange-color) !important;

  font-weight: 600;

  transition: 0.2s ease-in;

  > span {
    color: inherit !important;
    font-weight: inherit !important;
  }
}

.btn-primary,
.btn-primary.disabled {
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border: 1px solid var(--button-background-color);

  font-weight: 600;

  transition: 0.2s ease-in;

  > span {
    color: inherit !important;
    font-weight: inherit !important;
  }
}

.btn-circle {
  border-radius: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--title-color);
}

p,
span:not(
    .p-button-label,
    .p-button-icon,
    .text-primary,
    .text-orange,
    .text-action,
    .text-secondary,
    .text-warning,
    .text-danger,
    .text-info
  ) {
  color: var(--text-color);
}

mv-sidebar {
  .wrapper {
    background-color: var(--menu-background-color);
  }

  .submenu-list > mv-sidebar-item {
    background-color: var(--sub-menu-background-color);

    a {
      background-color: var(--sub-menu-background-color);
      color: var(--sub-menu-text-color) !important;

      &:hover {
        background-color: var(--sub-menu-background-hover-color) !important;
        color: var(--sub-menu-text-hover-color) !important;
      }
    }
  }

  mv-sidebar-item {
    background-color: var(--menu-background-color);

    a {
      background-color: var(--menu-background-color);
      color: var(--menu-text-color) !important;

      &:hover {
        background-color: var(--menu-background-hover-color) !important;
        color: var(--menu-text-hover-color) !important;
      }
    }
  }
}

.btn-success {
  background-color: var(--success-color);
  color: var(--white-color);
  border-color: var(--success-color);

  &:hover {
    background-color: var(--success-hover-color) !important;
    color: var(--white-color) !important;
    border-color: var(--success-hover-color) !important;
  }
}

.btn-info {
  background-color: var(--info-color);
  color: var(--white-color);
  border-color: var(--info-color);

  &:hover {
    background-color: var(--info-hover-color) !important;
    color: var(--white-color) !important;
    border-color: var(--info-hover-color) !important;
  }
}

.btn-danger {
  background-color: var(--error-color);
  color: var(--white-color);
  border-color: var(--error-color);

  &:hover {
    background-color: var(--error-hover-color) !important;
    color: var(--white-color) !important;
    border-color: var(--error-hover-color) !important;
  }
}

.btn-warning {
  background-color: var(--warning-color);
  color: var(--white-color);
  border-color: var(--warning-color);

  &:hover {
    background-color: var(--warning-hover-color) !important;
    color: var(--white-color) !important;
    border-color: var(--warning-hover-color) !important;
  }
}

.btn-action:not(p-button) {
  background-color: var(--action-button-background-color);
  color: var(--action-button-text-color);
  border-color: var(--action-button-background-color);

  p,
  span,
  a,
  i {
    color: var(--action-button-text-color);
  }

  &:hover {
    background-color: var(--action-button-background-hover-color);
    color: var(--action-button-text-hover-color);
    border-color: var(--action-button-background-hover-color);

    p,
    span,
    a,
    i {
      color: var(--action-button-text-hover-color);
    }
  }
}

.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem var(--lavander-color);
}

button:focus,
.btn:focus,
.p-button:focus {
  box-shadow: none;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--lavander-color);
  background: var(--lavander-color);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: var(--dark-color);
  background: var(--dark-color);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 0 0.2rem var(--lavander-color);
  border-color: var(--dark-color);
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  cursor: not-allowed;
  border: none !important;

  background-color: var(--action-button-background-color);

  opacity: 0.5 !important;
  transition: 0.2s ease-in;
}

.p-tooltip {
  z-index: 50000000000000000 !important;
}

::ng-deep p-dynamicdialog {
  .p-dialog-mask-scrollblocker {
    z-index: 500000 !important;
  }
}

::ng-deep ngb-modal-backdrop {
  z-index: 100010 !important;
}

::ng-deep ngb-modal-window.modal {
  z-index: 999000 !important;
}

::ng-deep ngb-modal-window.modal ~ .ck-body-wrapper {
  z-index: 999000 !important;
  .ck-balloon-panel {
    z-index: 999001 !important;
  }
}

::ng-deep .ck-powered-by,
::ng-deep .ck-powered-by-balloon {
  display: none !important;
  opacity: 0 !important;
}

.b-r-20 {
  border-radius: 20px;
}

.modal-color-choices .color-choice {
  width: 30px;
  height: 30px;
  margin: 10px;
  display: inline-block;
  border: #666666 dotted 0.5px;
}

.modal-content {
  position: relative;

  box-shadow: 0 0 16px #a7a7a7;
}

.modal-color-choices .color-choice.selected {
  border: #000000 solid 2px;
}
div.inlive-div {
  position: absolute;
  /* min-width: 200px; */
  max-width: 300px;
  max-height: 300px;
  background-color: white;
  z-index: 500;
  border: 0.5px solid #777777;
  border-radius: 3px;
  overflow-y: scroll;
  font-size: small;
  cursor: default;
}

.dropdown-toggle::after {
  display: none;
}
.dropdown .dropdown-menu {
  padding: 0;
  margin: 0;
  font-size: small;
  z-index: 2001;
}

.inline-line {
  margin-left: 0px;
  margin-right: 0px;
  padding: 3px;
  padding-right: 25px;
}

.inline-line i {
  margin-top: 3px;
}
.inline-line i.check {
  position: absolute;
  right: 10px;
  margin-top: 3px;
}

/**** MENU ***/

li.menu-item,
li.menu-subitem {
  cursor: pointer;
  list-style: none;
  margin: 10px;
  height: 40px;
  width: 300px;
  padding: 5px;
  padding-left: 16px;
  padding-top: 8px;
  border-radius: 8px;
  margin-left: 5px;
  color: #99abb4;
  display: block;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
      Introduced in IE 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
  -ms-user-select: none;
  user-select: none;
}

body:not(.mini-sidebar) li.menu-item,
body:not(.mini-sidebar) li.menu-subitem {
  display: inline-block;
  width: 230px;
  margin: 5px;
}

li.menu-item {
  width: 50px;
}

li.menu-item:hover {
  width: 300px;
}

li.menu-item:hover,
li.menu-item.active {
  background-color: #26c6da;
  color: #ffffff;
}

li.menu-subitem {
  width: 230px;
}

li.menu-item > .menu-text {
  display: none;
}

body:not(.mini-sidebar) li.menu-item > .menu-text {
  display: unset;
}

.menu-icon {
  width: 27px;
  height: 27px;
  font-size: 21px;
  display: inline-block;
  vertical-align: middle;
}

li.menu-item:hover > .menu-text {
  display: unset;
}

/*ul.general-menu li.menu-item:not(.active):hover {
    color: #26c6da !important;
}*/

li.menu-subitem:hover,
li.menu-subitem.active {
  color: #26c6da;
}

body.mini-sidebar li.menu-item:hover > ul,
body.mini-sidebar li.menu-subitem:hover > ul,
body:not(.mini-sidebar) li.menu-item.active > ul,
body:not(.mini-sidebar) li.menu-subitem.active > ul {
  display: block !important;
  background-color: white;
  margin-top: -5px;
  padding: 0px;
  z-index: 1000;
}

body:not(.mini-sidebar) li.menu-item.active > ul,
body:not(.mini-sidebar) li.menu-subitem.active > ul {
  margin-top: 6px;
  margin-left: -11px;
}

body:not(.mini-sidebar) li.menu-item.has-submenu > .menu-text:after,
body:not(.mini-sidebar) li.menu-subitem.has-submenu > .menu-text:after {
  content: '►';
  position: absolute;
  right: 11px;
  margin-top: 5px;
  font-size: 10px;
}

body:not(.mini-sidebar) li.menu-item.has-submenu.active > .menu-text:after,
body:not(.mini-sidebar) li.menu-subitem.has-submenu.active > .menu-text:after {
  transform: rotate(90deg);
}

body.mini-sidebar li.menu-item:hover > ul {
  margin-left: 45px;
}

body.mini-sidebar body:not(.mini-sidebar) li.menu-item:hover > ul {
  margin-left: -6px;
}

body.mini-sidebar li.menu-subitem:hover > ul {
  margin-left: 213px;
  width: 230px;
  margin-top: -33px;
}

ul.general-menu {
  padding: 0px;
  padding-top: 10px;
}

/** SETTINGS **/

.settings-button {
  color: white;
  position: fixed;
  left: 9px;
  bottom: 5px;
  z-index: 5000;
}

.deleteCategory {
  color: red;
  right: 15px;
  position: absolute;
}

.container-fluid {
  padding-right: 30px;
}

.chat-maxi .container-fluid {
  padding-right: 250px;
}

/*** Pour qu'on voit les select 2 même dans des panel particuliers - nécessaire pour le chat */

.chat-container .select2-container {
  z-index: 2000;
}

.settings-options {
  position: fixed;
  right: 0px;
  top: 0;
  left: 240px;
  z-index: 1002;
  color: white;
  padding: 18.5px;
  background-color: orange;
}

.miniSidebar .settings-options {
  left: var(--sidebarwidth);
}

::ng-deep .settings-options .mat-checkbox-frame {
  border-color: #ffb22b !important;
}

::ng-deep th i.fa-eye,
::ng-deep th i.fa-eye-slash {
  margin-left: 5px;
}

::ng-deep table.dataTable thead th,
::ng-deep table.dataTable thead td {
  padding: 7px 12px;
}

.page-titles {
  padding-bottom: 3px;
  padding-top: 3px;
}

/**** COlonne des champs dans le paramétrage ***/

.col-settings {
  margin: 8px !important;
  padding: 0px !important;
  border-left: 1px solid grey;
  padding-left: 10px !important;
  overflow: scroll;
  height: 500px;
  width: 23%;
}

.col-data {
  margin: 0px !important;
  padding: 0px !important;
}

.field-def {
  background-color: #1e88e5;
  border: 0.05px solid grey;
  border-radius: 4px;
  margin-top: 5px;
  padding: 5px;
  color: white;
}

.field-def.used {
  background-color: grey;
}

.dnd-sortable-drag {
  border: 1px dashed orange;
}

.sort-handle {
  color: grey;
  font-size: 14px;
  margin-right: -5px;
}

.sort-handle:hover {
  color: orange;
}

/**** Menu *****/

.sidebar-nav ul li > a.active,
.sidebar-nav a.active:hover,
.sidebar-nav ul li.active > a {
  color: #ffffff;
  background: #26c6da !important;
}

.sidebar-nav ul li:not(.active) > a:hover {
  color: #26c6da !important;
}

.sidebar-nav .has-arrow::after {
  right: 0.3em !important;
}

.mini-sidebar .sidebar-nav #sidebarnav > li:hover > a {
  color: #ffffff !important;
}

.ui-dialog-titlebar.ui-widget-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.ui-toast-bottom-center {
  margin-left: -15vw;
  width: 30vw;
}

.ui-dialog-titlebar.ui-widget-header .ui-dialog-title {
  flex: 1;
}

input::placeholder,
input.form-control::placeholder,
.ng-select .ng-select-container .ng-value-container .ng-placeholder {
  color: #c2c2c2 !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}

::ng-deep ng-select {
  .ng-select-container {
    border: 1.5px solid #ebedf0;
    border-radius: 10px;
    box-sizing: border-box;

    font-weight: 600;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    &:hover {
      box-shadow: none;
      cursor: pointer;
      border: 1px solid var(--primary-color);
    }

    input:hover {
      cursor: pointer;
    }
  }
}

::ng-deep .tox-progress-bar,
::ng-deep .tox-progress-indicator {
  max-width: 100%;
}

.p-slider .p-slider-range {
  // background: #3b494f;
  // background: #001438;
  background: #df8d67;
  border-radius: 5px;
}

.p-slider .p-slider-handle {
  height: 1.543rem;
  width: 1.543rem;

  border: 2px solid #df8d67;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: calc(-0.5715rem - 0.2rem);
  margin-left: calc(-0.5715rem - 0.2rem);
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #df8d67;
  border-color: #df8d67;
}

.breakable {
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
}

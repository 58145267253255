/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/

/*Theme Colors*/
/*bootstrap Color*/
/*Light colors*/
/*Normal Color*/
/*Extra Variable*/
/*Preloader*/
.preloader {
  width: 100%;
  height: 100%;
  top: 0px;
  position: fixed;
  z-index: 99999;
  background: #fff;
}

.preloader .cssload-speeding-wheel {
  position: absolute;
  top: calc(50% - 3.5px);
  left: calc(50% - 3.5px);
}

/*******************
/*Top bar
*******************/
.topbar {
  background: #1e88e5;
}

.topbar .navbar-header {
  background: rgba(0, 0, 0, 0.05);
}

.topbar .top-navbar .navbar-header .navbar-brand .dark-logo {
  display: none;
}

.topbar .top-navbar .navbar-header .navbar-brand .light-logo {
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
}

.topbar .navbar-light .navbar-nav .nav-item > a.nav-link {
  color: #ffffff !important;
}

.topbar .navbar-light .navbar-nav .nav-item > a.nav-link:hover,
.topbar .navbar-light .navbar-nav .nav-item > a.nav-link:focus {
  color: rgba(255, 255, 255, 0.8) !important;
}

/*******************
/*General Elements
*******************/
a.link:hover,
a.link:focus {
  color: #1e88e5 !important;
}

.bg-theme {
  background-color: #1e88e5 !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #1e88e5;
  border-color: #1e88e5;
}

.right-sidebar .rpanel-title {
  background: #1e88e5;
}

.stylish-table tbody tr:hover,
.stylish-table tbody tr.active {
  border-left: 4px solid #1e88e5;
}

.text-themecolor {
  color: #1e88e5 !important;
}

.profile-tab li a.nav-link.active,
.customtab li a.nav-link.active {
  border-bottom: 2px solid #1e88e5;
  color: #1e88e5;
}

.profile-tab li a.nav-link:hover,
.customtab li a.nav-link:hover {
  color: #1e88e5;
}

/*******************
/*Buttons
*******************/
.btn-themecolor,
.btn-themecolor.disabled {
  background: #1e88e5;
  color: #ffffff;
  border: 1px solid #1e88e5;
}

.btn-themecolor:hover,
.btn-themecolor.disabled:hover {
  background: #1e88e5;
  opacity: 0.7;
  border: 1px solid #1e88e5;
}

.btn-themecolor.active,
.btn-themecolor:focus,
.btn-themecolor.disabled.active,
.btn-themecolor.disabled:focus {
  background: #028ee1;
}

/*******************
/*sidebar navigation
*******************/
